import { Container, Group, Text, createStyles } from '@mantine/core'
import type { GetApiV1UserMe200 } from '@testsa-com/common/src/codegen/generated/Api.schemas'
import Link from 'next/link'

import type { MenuLink } from '@testsa-com/common/src/types/Menu.type'
import styles from '../styles/layout/Footer.module.scss'

const useStyles = createStyles(theme => ({
  inner: {
    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
    },
  },

  links: {
    [theme.fn.smallerThan('xs')]: {
      marginTop: theme.spacing.md,
    },
  },
}))

interface FooterProps {
  links: MenuLink[]
  userType: GetApiV1UserMe200['type'] | undefined
}

export function Footer({ links, userType }: FooterProps) {
  const { classes, cx } = useStyles()
  const items = links.map(l =>
    l.subLinks ? (
      l.subLinks.map(sl => (
        <Link key={sl.label} href={sl.link ?? ''}>
          <Text color="dimmed" size="sm">
            {sl.label}
          </Text>
        </Link>
      ))
    ) : (
      <Link key={l.label} href={l.link ?? ''}>
        <Text color="dimmed" size="sm">
          {l.label}
        </Text>
      </Link>
    ),
  )

  return (
    <footer className={styles.footer}>
      <Container className={cx(styles.inner, classes.inner)}>
        {userType === 'user' && <Group className={classes.links}>{items}</Group>}
      </Container>
    </footer>
  )
}
